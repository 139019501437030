
import { defineComponent } from "vue";
import { Editor } from "@tiptap/core";
import { Decoration, DecorationSet } from "prosemirror-view";
import { Cursor as CursorInterface } from "./cursors";
import Cursor from "./Cursor.vue";

export default defineComponent({
  name: "Cursors",
  components: {
    Cursor,
  },
  data() {
    return {
      user: { uid: "", name: "", color: "" } as {
        uid: string;
        name: string;
        color: string;
      },
      cursors: {} as { [key: string]: any },
      pos: {},
    };
  },
  props: {
    editor: {
      required: true,
    },
  },
  methods: {
    getCursorHighlights: function (doc: any): DecorationSet {
      return DecorationSet.create(
        doc,
        Object.keys(this.cursors)
          .filter(
            (cursor) =>
              this.cursors[cursor].pos != null &&
              this.cursors[cursor].pos.head != this.cursors[cursor].pos.anchor
          )
          .map((cursor) => {
            if (
              this.cursors[cursor].pos.anchor < this.cursors[cursor].pos.head
            ) {
              return Decoration.inline(
                this.cursors[cursor].pos.anchor,
                this.cursors[cursor].pos.head,
                {
                  class: `bg-highlight-${this.cursors[cursor].color}`,
                }
              );
            } else {
              return Decoration.inline(
                this.cursors[cursor].pos.head,
                this.cursors[cursor].pos.anchor,
                {
                  class: `bg-highlight-${this.cursors[cursor].color}`,
                }
              );
            }
          })
      );
    },
    mapCursors: function (tr: any) {
      Object.keys(this.cursors)
        .filter((cursor) => this.cursors[cursor].pos != null)
        .forEach((cursor) => {
          this.cursors[cursor].pos.anchor = tr.mapping.map(
            this.cursors[cursor].pos.anchor
          );
          this.cursors[cursor].pos.head = tr.mapping.map(
            this.cursors[cursor].pos.head
          );
        });
    },
    setCursor: function (cursor: CursorInterface) {
      //console.log(cursor);
      if (this.$refs["cursor-wrapper"])
        this.pos = (
          this.$refs["cursor-wrapper"] as any
        ).getBoundingClientRect();
      if (cursor.type == "+") {
        this.cursors[cursor.uid] = {
          uid: cursor.uid,
          name: cursor.name,
          color: cursor.color,
          pos: cursor.pos,
        };
      } else if (cursor.type == "-") {
        delete this.cursors[cursor.uid];
      } else if (cursor.type == "=") {
        this.cursors[cursor.uid].pos = cursor.pos;
      }
    },
    setUser: function (user: { uid: string; name: string; color: string }) {
      this.user = user;
    },
  },
});
