
import { defineComponent, nextTick } from "vue";
import store from "@/store/index";
import { SignInState } from "@/store/workspace/types";
import { Page } from "@/store/pages/types";

import LoadingPage from "@/components/layout/loading/LoadingPage.vue";

export default defineComponent({
  name: "AuthGuard",
  components: {
    LoadingPage,
  },
  data() {
    return {
      // Workspace
      pageErrored: false,
      findFailed: false,
      // time
      startClock: Date.now(),
    };
  },
  mounted: function () {
    if (this._page) {
      this.$emit("valid", this.startClock);
    }
  },
  watch: {
    _page: function (newPage, oldPage) {
      this.startClock = new Date().getTime();
      this.$emit("loading");
      nextTick(() => {
        if (newPage) {
          this.$emit("valid", this.startClock);
        } else {
          this.findFailed = false;
          setTimeout(() => {
            if (!this._page) this.findFailed = true;
          }, 6000);
        }
      });
    },
  },
  computed: {
    _pageId: function () {
      return this.$route.params.pageId;
    },
    _page: function () {
      return store.getters["pages/getPage"](this.$route.params.pageId);
    },
  },
});
