import store from "@/store/index";
import router from "@/router/index";
import { Editor, Range, findParentNodeClosestToPos } from "@tiptap/core";
//import { Material } from "@/store/materials/types";
//import { Message, MessageType } from "@/store/messages/types";
import { SuggestionItem, SuggestionGroup } from "./types";
import { Member } from "@/store/workspace/types";
//import { Relationship } from "@/store/relationships/types";
//import { Property, PropertyType } from "@/store/relationships/types";
import {
  Page,
  //Lead,
  //PropertyRule,
} from "@/store/pages/types";

/* Formatting --------------------------------------------------------------- */

export const bold = function (): SuggestionItem {
  return {
    key: "bold",
    title: "Bold",
    icon: ["fal", "bold"],
    group: SuggestionGroup.Typeography,
    command: ({ editor, range }: any) => {
      editor.chain().focus().deleteRange(range).toggleMark("bold").run();
    },
  };
};

export const italic = function (): SuggestionItem {
  return {
    key: "italic",
    title: "Italic",
    icon: ["fal", "italic"],
    group: SuggestionGroup.Typeography,
    command: ({ editor, range }: any) => {
      editor.chain().focus().deleteRange(range).toggleMark("italic").run();
    },
  };
};

export const underline = function (): SuggestionItem {
  return {
    key: "underline",
    title: "Underline",
    icon: ["fal", "underline"],
    group: SuggestionGroup.Typeography,
    command: ({ editor, range }: any) => {
      editor.chain().focus().deleteRange(range).toggleMark("underline").run();
    },
  };
};

export const heading1 = function (): SuggestionItem {
  return {
    key: "h1",
    title: "Heading 1",
    icon: ["fal", "h1"],
    group: SuggestionGroup.Formatting,
    command: ({ editor, range }: any) => {
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .setNode("heading", { level: 1 })
        .run();
    },
  };
};

export const heading2 = function (): SuggestionItem {
  return {
    key: "h2",
    title: "Heading 2",
    icon: ["fal", "h2"],
    group: SuggestionGroup.Formatting,
    command: ({ editor, range }: any) => {
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .setNode("heading", { level: 2 })
        .run();
    },
  };
};

export const heading3 = function (): SuggestionItem {
  return {
    key: "h3",
    title: "Heading 3",
    icon: ["fal", "h3"],
    group: SuggestionGroup.Formatting,
    command: ({ editor, range }: any) => {
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .setNode("heading", { level: 3 })
        .run();
    },
  };
};

export const bulletList = function (): SuggestionItem {
  return {
    key: "unordered list",
    title: "Bullet List",
    icon: ["fal", "list-ul"],
    group: SuggestionGroup.Formatting,
    command: ({ editor, range }: any) => {
      editor.chain().focus().deleteRange(range).toggleUnorderedList().run();
    },
  };
};

export const orderedList = function (): SuggestionItem {
  return {
    key: "ordered list",
    title: "Ordered List",
    icon: ["fal", "list-ol"],
    group: SuggestionGroup.Formatting,
    command: ({ editor, range }: any) => {
      editor.chain().focus().deleteRange(range).toggleOrderedList().run();
    },
  };
};

export const taskList = function (): SuggestionItem {
  return {
    key: "check list",
    title: "Check List",
    icon: ["fal", "list-check"],
    group: SuggestionGroup.Formatting,
    command: ({ editor, range }: any) => {
      editor.chain().focus().deleteRange(range).toggleTaskList().run();
    },
  };
};

export const quote = function (): SuggestionItem {
  return {
    key: "blockquote",
    title: "Quote",
    icon: ["fal", "quotes"],
    group: SuggestionGroup.Formatting,
    command: ({ editor, range }: any) => {
      editor.chain().focus().deleteRange(range).toggleBlockquote().run();
    },
  };
};

export const splitLayout = function (): SuggestionItem {
  return {
    key: "split",
    title: "Split Layout",
    icon: ["fal", "table-columns"],
    group: SuggestionGroup.Formatting,
    command: (props: any) => {
      const { selection } = props.editor.state;
      const table = findParentNodeClosestToPos(
        selection.ranges[0].$from,
        (node) => {
          return node.type.name === "table";
        }
      );
      if (table) return false;
      return props.editor
        .chain()
        .focus()
        .deleteRange(props.range)
        .insertTable({ rows: 1, cols: 2, withHeaderRow: false })
        .run();
    },
  };
};

/* Mentions ----------------------------------------------------------------- */

export const mentionItem = function (member: Member): SuggestionItem {
  return {
    key: member.email,
    title: member.name,
    icon: ["fal", "at"],
    group: SuggestionGroup.Mention,
    command: ({ editor, range }: any) => {
      editor
        .chain()
        .focus()
        .insertContentAt(range, [
          {
            type: "Mention",
            attrs: { user: member.email, pinned: false },
          },
          { type: "text", text: " " },
        ])
        .run();
    },
  };
};

/* Materials ---------------------------------------------------------------- */

/*
export const createMaterial = function (): SuggestionItem {
  return {
    key: "material",
    title: "Create Material",
    icon: ["fal", "plus-square"],
    group: SuggestionGroup.Priority,
    command: ({ editor, range }: any) => {
      const material = Material.createDefault(
        "",
        (router.currentRoute as any)._value.params.pageId,
        MessageType.Email,
        "New Material"
      );
      store
        .dispatch("materials/create", material)
        .then((material: Material) => {
          editor
            .chain()
            .focus()
            .insertContentAt(range, [
              {
                type: "MaterialMention",
                attrs: {
                  material: material.id,
                  page: (router.currentRoute as any)._value.params.pageId,
                },
              },
              { type: "text", text: " " },
            ])
            .run();
        });
    },
  };
};

export const linkMaterial = function (page: string) {
  return () => {
    return [...store.getters["pages/getLocalMaterials"](page)].map(
      (material: string) => {
        return {
          key: "material-" + store.getters["materials/getSubject"](material),
          title: store.getters["materials/getSubject"](material),
          icon: Message.typeIcon[
            store.getters["materials/getType"](material) as MessageType
          ],
          group: SuggestionGroup.Materials,
          command: ({ editor, range }: any) => {
            editor
              .chain()
              .focus()
              .insertContentAt(range, [
                {
                  type: "MaterialMention",
                  attrs: { material: material, page: page },
                },
              ])
              .run();
          },
        };
      }
    );
  };
};
*/

/* Messages ----------------------------------------------------------------- */

/*
export const embedTweet = function (): SuggestionItem {
  return {
    key: "tweet",
    title: "Embed Tweet",
    icon: ["fab", "twitter"],
    group: SuggestionGroup.Messages,
    command: ({ editor, range }: { editor: Editor; range: Range }) => {
      editor
        .chain()
        .focus()
        .insertContentAt(range, [
          { type: "TweetBlock", attrs: { link: "" } },
          { type: "text", text: " " },
        ])
        .run();
    },
  };
};
*/

/* Relationships ------------------------------------------------------------ */
/*
export const createLead = function (): SuggestionItem {
  return {
    key: "lead",
    title: "Add Person",
    icon: ["fal", "plus-square"],
    group: SuggestionGroup.Priority,
    command: ({ editor, range }: any) => {
      store
        .dispatch(
          "pages/createLead",
          new Lead("", (router.currentRoute as any)._value.params.pageId, []),
          { root: true }
        )
        .then((lead: Lead) => {
          editor
            .chain()
            .focus()
            .insertContentAt(range, [
              {
                type: "LeadMention",
                attrs: { lead: lead.id, page: lead.page },
              },
              { type: "text", text: " " },
            ])
            .run();
        });
    },
  };
};

export const mentionLead = function (page: string) {
  console.log(store.getters["pages/getLeads"](page));
  return () => {
    try {
      return store.getters["pages/getLeads"](page).map((lead: Lead) => {
        console.log(lead);
        console.log(lead.relationships);
        console.log(
          lead.relationships.map((id: string) =>
            store.getters["relationships/getRelationship"](id)
          )
        );
        let title;
        if (lead.name.length > 0) title = lead.name;
        else if (lead.relationships.length > 0)
          title = store.getters["relationships/getName"](lead.relationships[0]);
        else title = "( empty )";
        return {
          key: "lead-" + title,
          title: title,
          icon:
            lead.relationships.length > 1 ? ["fal", "users"] : ["fal", "user"],
          group: SuggestionGroup.Relationships,
          command: ({ editor, range }: any) => {
            editor
              .chain()
              .focus()
              .insertContentAt(range, [
                {
                  type: "LeadMention",
                  attrs: { lead: lead.id, page: lead.page },
                },
                { type: "text", text: " " },
              ])
              .run();
          },
        };
      });
    } catch (error) {
      store.getters["pages/getLeads"](page).map((lead: Lead) => {
        lead.relationships.forEach((relationship) => {
          if (
            store.getters["relationships/getRelationship"](relationship) ==
            "undefined"
          ) {
            store.dispatch("pages/removeRelationship", {
              page: lead.page,
              lead: lead.id,
              realtionship: relationship,
            });
          }
        });
      });
      return [];
    }
  };
};
*/
/* Properties */
/*
export const properties = function (relationship: string) {
  return () => {
    return (
      Object.values(
        store.getters["relationships/getProperties"](relationship)
      ) as Array<Property>
    ).map((property: Property) => {
      return {
        key: "property",
        title: property.key,
        icon: Property.typeIcon[property.type],
        group: SuggestionGroup.Property,
        command: ({ editor, range }: any) => {
          editor
            .chain()
            .focus()
            .insertContentAt(range, [
              {
                type: "PropertyMention",
                attrs: { relationship: relationship, property: property.id },
              },
              { type: "text", text: " " },
            ])
            .run();
        },
      };
    });
  };
};

export const addProperty = function (relationship: string) {
  return () => {
    return {
      key: "new property",
      title: "Create Property",
      icon: ["fal", "plus-square"],
      group: SuggestionGroup.Property,
      command: ({ editor, range }: any) => {
        store
          .dispatch("relationships/addProperty", {
            relationship: relationship,
            property: Property.createDefault(
              "",
              "key",
              PropertyType.Text,
              null
            ),
          })
          .then((property: Property) => {
            editor
              .chain()
              .focus()
              .insertContentAt(range, [
                {
                  type: "PropertyMention",
                  attrs: { relationship: relationship, property: property.id },
                },
                { type: "text", text: " " },
              ])
              .run();
          });
      },
    };
  };
};

export const callProperties = function (relationship: string) {
  return () => {
    const rel = store.getters["relationships/getRelationship"](relationship);
    if (typeof rel == "undefined") {
      return [];
    } else {
      const out = (
        Object.values(
          store.getters["relationships/getProperties"](relationship)
        ) as Array<Property>
      ).map((property: Property) => {
        return {
          key: property.key,
          title: property.key + ": " + property.toString(),
          icon: Property.typeIcon[property.type],
          group: SuggestionGroup.Property,
          command: ({ editor, range }: any) => {
            editor
              .chain()
              .focus()
              .insertContentAt(range, property.toString())
              .run();
          },
        };
      });
      out.push({
        key: "name",
        title: "name: " + rel.name,
        icon: Property.typeIcon[0],
        group: SuggestionGroup.Priority,
        command: ({ editor, range }: any) => {
          editor.chain().focus().insertContentAt(range, rel.name).run();
        },
      });
      return out;
    }
  };
};

export const tagProperty = function (page: string) {
  return () => {
    return {
      key: "create property",
      title: "Add Property",
      icon: ["fal", "plus-square"],
      group: SuggestionGroup.Property,
      command: ({ editor, range }: any) => {
        store
          .dispatch(
            "pages/addProperty",
            new PropertyRule("", page, PropertyType.Text, "New Property")
          )
          .then((property) => {
            editor
              .chain()
              .focus()
              .insertContentAt(range, [
                {
                  type: "PropertyTag",
                  attrs: { page: property.page, property: property.id },
                },
                { type: "text", text: " " },
              ])
              .run();
          });
      },
    };
  };
};

export const tagProperties = function (page: string) {
  return () => {
    const out = (
      Object.values(
        store.getters["pages/getProperties"](page)
      ) as Array<Property>
    ).map((property: Property) => {
      return {
        key: property.key,
        title: property.key,
        icon: Property.typeIcon[property.type],
        group: SuggestionGroup.Property,
        command: ({ editor, range }: any) => {
          editor
            .chain()
            .focus()
            .insertContentAt(range, [
              {
                type: "PropertyTag",
                attrs: { page: property.page, property: property.id },
              },
              { type: "text", text: " " },
            ])
            .run();
        },
      };
    });
    out.push({
      key: "name",
      title: "name",
      icon: Property.typeIcon[PropertyType.Text],
      group: SuggestionGroup.Priority,
      command: ({ editor, range }: any) => {
        editor
          .chain()
          .focus()
          .insertContentAt(range, [
            { type: "PropertyTag", attrs: { page: page, property: "name" } },
            { type: "text", text: " " },
          ])
          .run();
      },
    });
    return out;
  };
};

export const writeProperties = function (
  relationships: () => Relationship | Array<Relationship>
): () => Array<SuggestionItem> {
  return () => {
    const result = relationships();
    let list;
    if (typeof relationships == "object") list = [result as Relationship];
    else list = result as Array<Relationship>;
    const out: Array<SuggestionItem> = [];
    list.forEach((relationship) => {
      Object.values(relationship.properties).forEach((property: Property) => {
        out.push({
          key: property.key,
          title: `${relationship.name}:${property.key}`,
          icon: Property.typeIcon[property.type],
          group: SuggestionGroup.Property,
          command: ({ editor, range }: any) => {
            editor
              .chain()
              .focus()
              .insertContentAt(range, [
                {
                  type: "text",
                  text: property.toNode(),
                },
              ])
              .run();
          },
        });
      });
      out.push({
        key: "name",
        title: "name",
        icon: Property.typeIcon[PropertyType.Text],
        group: SuggestionGroup.Priority,
        command: ({ editor, range }: any) => {
          editor
            .chain()
            .focus()
            .insertContentAt(range, [{ type: "text", text: relationship.name }])
            .run();
        },
      });
    });
    return out;
  };
};
*/
/* Page --------------------------------------------------------------------- */

export const createPage = function (): SuggestionItem {
  return {
    key: "page",
    title: "Create Page",
    icon: ["fal", "plus-square"],
    group: SuggestionGroup.Priority,
    command: ({ editor, range }: { editor: Editor; range: Range }) => {
      store
        .dispatch(
          "pages/create",
          Page.create(
            "",
            (router.currentRoute as any)._value.params.pageId,
            "New Page",
            ""
          )
        )
        .then((page: Page) => {
          editor
            .chain()
            .focus()
            .insertContentAt(range, [
              { type: "PageMention", attrs: { page: page.id } },
            ])
            .run();
        });
    },
  };
};

export const linkPage = function (page: string) {
  return () => {
    return store.getters["pages/getChildren"](page).map((child: string) => {
      return {
        key: "page-" + store.getters["pages/getName"](child),
        title: store.getters["pages/getName"](child),
        icon: store.getters["pages/getIcon"](child),
        group: SuggestionGroup.Pages,
        command: ({ editor, range }: { editor: Editor; range: Range }) => {
          editor
            .chain()
            .focus()
            .insertContentAt(range, [
              { type: "PageMention", attrs: { page: child } },
            ])
            .run();
        },
      };
    });
  };
};

/*
export const createInbox = function (): SuggestionItem {
  return {
    key: "inbox",
    title: "Create Inbox",
    icon: ["fal", "plus-square"],
    group: SuggestionGroup.Priority,
    command: ({ editor, range }: { editor: Editor; range: Range }) => {
      store
        .dispatch(
          "pages/create",
          Page.create(
            "",
            (router.currentRoute as any)._value.params.pageId,
            "New Inbox",
            "",
            [new InboxView("")]
          )
        )
        .then((page: Page) => {
          console.log(page);
          editor
            .chain()
            .focus()
            .insertContentAt(range, [
              { type: "PageNote", attrs: { page: page.id } },
            ])
            .run();
        });
    },
  };
};

export const createList = function (): SuggestionItem {
  return {
    key: "list",
    title: "Create List",
    icon: ["fal", "plus-square"],
    group: SuggestionGroup.Priority,
    command: ({ editor, range }: { editor: Editor; range: Range }) => {
      store
        .dispatch(
          "pages/create",
          Page.create(
            "",
            (router.currentRoute as any)._value.params.pageId,
            "New List",
            "",
            [new ListView()]
          )
        )
        .then((page: Page) => {
          editor
            .chain()
            .focus()
            .insertContentAt(range, [
              { type: "PageNote", attrs: { page: page.id } },
            ])
            .run();
        });
    },
  };
};

export const createBoard = function (): SuggestionItem {
  return {
    key: "board",
    title: "Create Board",
    icon: ["fal", "plus-square"],
    group: SuggestionGroup.Priority,
    command: ({ editor, range }: { editor: Editor; range: Range }) => {
      store
        .dispatch(
          "pages/create",
          Page.create(
            "",
            (router.currentRoute as any)._value.params.pageId,
            "New Board",
            "",
            [new BoardView({})]
          )
        )
        .then((page: Page) => {
          editor
            .chain()
            .focus()
            .insertContentAt(range, [
              { type: "PageNote", attrs: { page: page.id } },
            ])
            .run();
        });
    },
  };
};

export const createTable = function (): SuggestionItem {
  return {
    key: "table",
    title: "Create Table",
    icon: ["fal", "plus-square"],
    group: SuggestionGroup.Priority,
    command: ({ editor, range }: { editor: Editor; range: Range }) => {
      store
        .dispatch(
          "pages/create",
          Page.create(
            "",
            (router.currentRoute as any)._value.params.pageId,
            "New Table",
            "",
            [new TableView({})]
          )
        )
        .then((page: Page) => {
          editor
            .chain()
            .focus()
            .insertContentAt(range, [
              { type: "PageNote", attrs: { page: page.id } },
            ])
            .run();
        });
    },
  };
};

export const createTimeline = function (): SuggestionItem {
  return {
    key: "timeline",
    title: "Create Timeline",
    icon: ["fal", "plus-square"],
    group: SuggestionGroup.Priority,
    command: ({ editor, range }: { editor: Editor; range: Range }) => {
      store
        .dispatch(
          "pages/create",
          Page.create(
            "",
            (router.currentRoute as any)._value.params.pageId,
            "New Timeline",
            "",
            [new TimelineView(Timeframe.Week)]
          )
        )
        .then((page: Page) => {
          editor
            .chain()
            .focus()
            .insertContentAt(range, [
              { type: "PageNote", attrs: { page: page.id } },
            ])
            .run();
        });
    },
  };
};
*/

/*
export const createInbox = function(): SuggestionItem {
  return {
    key: "inbox",
    title: "Create Inbox",
    icon: ['fal', 'plus-square'],
    group: SuggestionGroup.Priority,
    command: ({ editor, range }: { editor: Editor, range: Range }) => {
      store.dispatch('pages/create', new Inbox(
        "",
        "New Inbox",
        randomEmoji(),
        (router.currentRoute as any)._value.params.pageId,
        "",
      )).then((page: Page) => {
        editor
          .chain()
          .focus()
          .insertContentAt(range, [
            { type: 'PageBlock', attrs: { page: page.id} },
            { type: 'text', text: ' ' },
          ])
          .run()
      })
    }
  }
}
*/
