import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (this.headPos != null && this.anchorPos != null)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "cursor",
        ref: "cursor",
        style: _normalizeStyle({
      top: _ctx.anchorPos.top - _ctx.parentPos.top - 16 + 'px',
      left: _ctx.anchorPos.left - _ctx.parentPos.left + 'px',
    })
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["ellipsis sub p-mention rounded-mention text-paper", `bg-pallet-${_ctx.cursor.color}`])
        }, _toDisplayString(_ctx.cursor.name), 3),
        _createElementVNode("div", {
          class: "absolute",
          style: _normalizeStyle({
        width: '2px',
        height: _ctx.anchorPos.bottom - _ctx.anchorPos.top + 'px',
        top: 16 + 'px',
        'background-color': _ctx.cursor.color,
      })
        }, null, 4),
        _createElementVNode("div", {
          class: _normalizeClass(["absolute", `bg-pallet-${_ctx.cursor.color}`]),
          style: _normalizeStyle({
        width: '2px',
        height: _ctx.headPos.bottom - _ctx.headPos.top + 'px',
        left: _ctx.headPos.left - _ctx.anchorPos.left + 'px',
        top: _ctx.headPos.top - _ctx.anchorPos.top + 16 + 'px',
      })
        }, null, 6)
      ], 4))
    : _createCommentVNode("", true)
}