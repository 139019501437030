import store from "@/store/index";
import { Editor } from "@tiptap/vue-3";

//import { Relationship } from "@/store/relationships/types";
//import { Lead } from "@/store/pages/types";

import {
  // Slash
  createPage,
  bold,
  italic,
  heading1,
  heading2,
  heading3,
  quote,
  bulletList,
  orderedList,
  taskList,

  //Plus
  /*
  createMaterial,
  tagProperty,
  createInbox,
  createList,
  createBoard,
  createTimeline,
  createTable,
  */

  // Equals
  //linkMaterial,
  linkPage,
  //tagProperties,
  //writeProperties,

  //  splitLayout,
} from "@/components/editor/suggestions";

import { HighlightContextMenu, ContextMenuType } from "@/store/util/types";

//import TweetBlock from "@/components/editor/extensions/tweet";
//import { LeadBlock, LeadMention } from "@/components/editor/extensions/lead";

//Base
import PageDocument from "./extensions/nodes/pageDoc";
import EmailDocument from "./extensions/nodes/emailDoc";
import Hero from "./extensions/nodes/hero";
import Text from "./extensions/nodes/text";

//Plugins
import SelectionHightlight from "./extensions/plugins/selection-highlight";
import SlashCommands from "./extensions/plugins/slash";
import PlusCommands from "./extensions/plugins/plus";
import EqualCommands from "./extensions/plugins/equal";
import HighlightMenu from "./extensions/plugins/highlight-menu";
import Collaboration from "./extensions/plugins/collaboration";
import Cursors from "./extensions/plugins/cursors";
import { receiveTransaction } from "prosemirror-collab";
import { Step } from "prosemirror-transform";

//Processors
//import EmailProcessor from "./extensions/plugins/processor";

//Marks
import Bold from "./extensions/marks/bold";
import Italic from "./extensions/marks/italic";
import Strike from "./extensions/marks/strike";
import Link from "@tiptap/extension-link";

//Nodes
import Paragraph from "./extensions/nodes/paragraph";
import Heading from "./extensions/nodes/heading";
import ListItem from "./extensions/nodes/listitem";
import CheckItem from "./extensions/nodes/checkItem";
import OrderedList from "./extensions/nodes/ordered";
import UnorderedList from "./extensions/nodes/unordered";
import CheckList from "./extensions/nodes/checkList";
import Blockquote from "./extensions/nodes/blockquote";
import Code from "./extensions/nodes/code";

// Mentions
import PageMention from "./extensions/mentions/page";
//import MaterialMention from "./extensions/mentions/material";
//import PropertyTag from "./extensions/mentions/propertyTag";

//Notes
import Note from "./extensions/notes/note";
import Collection from "./extensions/notes/collection";
//import PageNote from "./extensions/notes/page";
//import MaterialNote from "./extensions/notes/material";

import History from "@tiptap/extension-history";
import Placeholder from "@tiptap/extension-placeholder";
import Gapcursor from "@tiptap/extension-gapcursor";

//import Paragraph from "./extensions/paragraph";
import {
  Serif,
  Monospace,
  Script,
} from "@/components/editor/extensions/typography";
import Typography from "@tiptap/extension-typography";
import { EditorView } from "prosemirror-view";

EditorView.prototype.updateState = function updateState(state) {
  if (!(this as any).docView) return; // This prevents the matchesNode error on hot reloads
  (this as any).updateStateInner(state, this.state.plugins != state.plugins);
};

//Editors
export const createPageEditor = function (page: string, cursorsRef: any) {
  return new Editor({
    extensions: [
      // Base
      PageDocument,
      Hero,
      Collection,
      Text,

      //UX
      SelectionHightlight,
      HighlightMenu,
      SlashCommands([
        createPage,
        bold,
        italic,
        heading1,
        heading2,
        heading3,
        bulletList,
        orderedList,
        taskList,
        quote,
      ]),
      EqualCommands([linkPage(page)]),
      Collaboration(
        `wss://velocity.keryx.workers.dev/page/${store.getters["workspace/getId"]}/${page}/websocket`
      ),
      Cursors(cursorsRef),

      //Marks
      Bold,
      Italic,
      Strike,
      Link,

      //Nodes
      Paragraph,
      Heading,
      ListItem,
      CheckItem,
      OrderedList,
      UnorderedList,
      Blockquote,
      Code,
      CheckList,

      //Mentions
      PageMention,
      //MaterialMention,
      //PropertyTag,

      //Notes
      Note,
      //PageNote,
      //MaterialNote,
    ],
  });
};

/*
export const createMaterialEditor = function (
  material: string,
  cursorsRef: any
) {
  return new Editor({
    extensions: [
      // Base
      EmailDocument,
      Text,

      //UX
      SelectionHightlight,
      HighlightMenu,
      SlashCommands([
        bold,
        italic,
        heading1,
        heading2,
        heading3,
        bulletList,
        orderedList,
        quote,
      ]),
      Collaboration(
        `wss://velocity.keryx.workers.dev/material/${store.getters["workspace/getId"]}/${material}/websocket`
      ),
      Cursors(cursorsRef),

      //Marks
      Bold,
      Italic,
      Strike,
      Link,

      //Nodes
      Paragraph,
      Heading,
      ListItem,
      OrderedList,
      UnorderedList,
      Blockquote,
      Code,

      //Mentions,
      PropertyTag,
    ],
  });
};
*/

/*
export const createEmailEditor = function (
  relationships: () => Array<Relationship>,
  update: () => void
) {
  return new Editor({
    extensions: [
      // Base
      EmailDocument,
      Text,

      //UX
      SelectionHightlight,
      HighlightMenu,
      SlashCommands([
        bold,
        italic,
        heading1,
        heading2,
        heading3,
        bulletList,
        orderedList,
        quote,
      ]),

      //Marks
      Bold,
      Italic,
      Strike,
      Link,

      //Nodes
      Paragraph,
      Heading,
      ListItem,
      OrderedList,
      UnorderedList,
      Blockquote,
      Code,
    ],
    onBlur: update,
  });
};

export const toEmail = function (lead: Lead, content: Array<any>): string {
  console.log(content);
  const editor = new Editor({
    extensions: [
      // Base
      EmailDocument,
      Text,

      //Plugins
      EmailProcessor(
        lead.relationships.length > 0
          ? store.getters["relationships/getRelationship"](
              lead.relationships[0]
            )
          : new Relationship("")
      ),

      //Marks
      Bold,
      Italic,
      Strike,
      Link,

      //Nodes
      Paragraph,
      Heading,
      ListItem,
      OrderedList,
      UnorderedList,
      Blockquote,
      Code,
    ],
  });
  let steps, clientIds;
  if (content.length > 0) {
    [steps, clientIds] = content[0].map((_: any, colIndex: any) =>
      content.map((row: any) => row[colIndex])
    );
  } else {
    steps = [];
    clientIds = [];
  }
  editor.view.dispatch(
    receiveTransaction(
      editor.view.state,
      steps.map((s: any) => Step.fromJSON(editor.schema, s)),
      clientIds
    )
  );
  return editor.getHTML();
};
*/
