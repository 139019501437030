
import { defineComponent } from "vue";
import store from "@/store/index";
import { Page } from "@/store/pages/types";
import { ProfileHelper } from "@/store/util/types";
//import PathItem from "./PathItem.vue";
import PageMention from "@/components/pages/mentions/PageMention.vue";
import FountainToggle from "./FountainToggle.vue";
import Profile from "./Profile.vue";

export default defineComponent({
  name: "PagePath",
  components: {
    PageMention,
    FountainToggle,
    Profile,
  },
  computed: {
    _path: function (): Array<string> {
      return store.getters["pages/getPath"](this.$route.params.pageId);
    },
    _saving: function (): boolean {
      return store.getters["util/getSavingJobs"] > 0;
    },
    _hideNavbar: function (): boolean {
      return store.getters["workspace/hideNavbar"];
    },
    _fountain: function (): boolean {
      return store.getters["workspace/fountain"];
    },
  },
  methods: {
    closeWebsocket: function () {
      store.getters["workspace/getWebsocket"].close();
    },
    openPage: function (page: string) {
      this.$router.push(`/-/${this.$route.params.workspaceId}/${page}`);
    },
    toggleNavbar: function () {
      store.dispatch("workspace/toggleNavbar", !this._hideNavbar);
    },
    openProfile: function () {
      store.dispatch("util/pushHelper", new ProfileHelper());
    },
  },
});
