import { Plugin, PluginKey } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";
import { Extension } from "@tiptap/core";

export const SelectionHighlightPluginKey = new PluginKey("selection-highlight");

export default Extension.create({
  name: "SelectionHighlight",
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: SelectionHighlightPluginKey,
        state: {
          init(_, { doc }) {
            return DecorationSet.create(doc, []);
          },
          apply(tr, value, old, state) {
            const selection = state.selection;
            const decorations: Array<Decoration> = [];
            if ((selection as any).node && (selection as any).node.isBlock) {
              Decoration.node(selection.from, selection.to, {
                class: "node-selection",
              });
            }
            return DecorationSet.create(state.doc, decorations);
          },
        },
        props: {
          decorations(state) {
            return this.getState(state);
          },
        },
      }),
    ];
  },
});
