import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageContent = _resolveComponent("PageContent")!
  const _component_PageGuard = _resolveComponent("PageGuard")!

  return (_openBlock(), _createBlock(_component_PageGuard, {
    class: "flex flex-col items-stretch h-screen relative overflow-auto scrollbar-sm",
    id: "page",
    onValid: _ctx.handleValid,
    onLoading: _cache[0] || (_cache[0] = ($event: any) => (_ctx.valid = false))
  }, {
    default: _withCtx(() => [
      (_ctx.valid)
        ? (_openBlock(), _createBlock(_component_PageContent, { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onValid"]))
}