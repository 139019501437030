
import { defineComponent } from "vue";
import { NodeViewWrapper, nodeViewProps, NodeViewContent } from "@tiptap/vue-3";

export default defineComponent({
  name: "TaskItemNode",
  components: {
    NodeViewWrapper,
    NodeViewContent,
  },
  props: nodeViewProps,
  methods: {
    toggle: function () {
      (this.node as any).attrs.checked = !(this.node as any).attrs.checked;
    },
  },
});
