
import { defineComponent } from "vue";
import store from "@/store/index";
import { Editor, EditorContent } from "@tiptap/vue-3";
import { createPageEditor } from "@/components/editor/editors";
import { Page } from "@/store/pages/types";
import PageHero from "@/components/pages/cells/PageHero.vue";
import PagePath from "@/components/layout/navbar/PagePath.vue";
import Cursors from "@/components/editor/extensions/plugins/Cursors.vue";

export default defineComponent({
  name: "PageContent",
  components: {
    PageHero,
    PagePath,
    EditorContent,
    Cursors,
  },
  data() {
    return {
      editor: null as null | Editor,
    };
  },
  provide() {
    return {
      editor: this.editor,
    };
  },
  created: function () {
    store.dispatch("user/setLastOpen", {
      workspaceId: this.$route.params.workspaceId,
      pageId: this.$route.params.pageId,
    });
  },
  mounted: function () {
    this.editor = createPageEditor(
      this.$route.params.pageId as string,
      this.$refs["cursors"]
    );
  },
  computed: {
    _page: function (): Page {
      return store.getters["pages/getPage"](
        this.$route.params.pageId as string
      );
    },
    _fountain: function (): boolean {
      return store.getters["workspace/fountain"];
    },
  },
  beforeUnmount: function () {
    if (this.editor) {
      if (this.editor.storage.collaboration.websocket.readyState == 1) {
        this.editor.storage.collaboration.websocket.close();
      } else if (this.editor.storage.collaboration.websocket.readyState == 0) {
        const socket = this.editor.storage.collaboration.websocket;
        socket.addEventListener("open", () => {
          socket.close();
        });
      }

      this.editor.destroy();
    }
  },
});
