import store from "@/store/index";
import { Node, mergeAttributes, PasteRule } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-3";
import PageMentionNode from "./PageMentionNode.vue";

const PageMentionBlock = Node.create({
  name: "PageMention",

  group: "inline",
  inline: true,
  selectable: true,
  draggable: true,
  atom: true,

  addAttributes() {
    return {
      page: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-page"),
        renderHTML: (attributes) => {
          if (!attributes.page) {
            return {};
          }
          return {
            "data-page": attributes.page,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "page-mention",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["page-mention", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return VueNodeViewRenderer(PageMentionNode);
  },

  addPasteRules() {
    return [
      new PasteRule({
        find: new RegExp(
          /^<page-mention data-page="(?<id>[\w-]+)"><\/page-mention>$/,
          "g"
        ),
        handler: (props: any) => {
          const page = store.getters["pages/getPage"](props.match[1]);
          console.log(page);
          props.commands.insertContentAt(props.range, [
            { type: "PageMention", attrs: { page: props.match[1] } },
            { type: "text", text: " " },
          ]);
        },
      }),
    ];
  },
});

export default PageMentionBlock;
