import { Node, mergeAttributes } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-3";
import HeroNode from "./HeroNode.vue";

const Hero = Node.create({
  name: "hero",

  group: "hero",
  inline: false,
  selectable: false,
  atom: true,

  parseHTML() {
    return [
      {
        tag: "header",
      },
      {
        tag: "hero", // backwards compatability
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["span", mergeAttributes(HTMLAttributes)];
  },
});

export default Hero;
