import { Node, mergeAttributes } from "@tiptap/core";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    taskList: {
      toggleTaskList: () => ReturnType;
    };
  }
}

export default Node.create({
  name: "checkList",
  draggable: true,

  group: "block list",

  content() {
    return "checkItem+";
  },

  parseHTML() {
    return [
      {
        tag: 'ul[data-type="TaskList"]',
        priority: 51,
      },
      {
        tag: 'ol[data-type="checklist"]',
        priority: 51,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "ol",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        "data-type": "checklist",
      }),
      0,
    ];
  },

  addCommands() {
    return {
      toggleTaskList:
        () =>
        ({ commands }: any) => {
          return commands.toggleList(this.name, this.options.itemTypeName);
        },
    };
  },
});
