import { Plugin, PluginKey } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { Extension } from "@tiptap/core";
import store from "@/store/index";
import { ContextMenuType, HighlightContextMenu } from "@/store/util/types";

export const HighlightMenuPluginKey = new PluginKey("highlight-menu");

export default Extension.create({
  name: "HighlightMenu",
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: HighlightMenuPluginKey,
        view: (view: EditorView) => {
          return {
            update: (view, prev) => {
              const state = view.state;
              // Don't do anything if the document/selection didn't change
              if (
                prev &&
                prev.doc.eq(state.doc) &&
                prev.selection.eq(state.selection)
              ) {
                return;
              }
              if (state.selection.empty || (state.selection as any).node) {
                if (
                  store.getters["util/getMenu"] != null &&
                  store.getters["util/getMenu"].type ==
                    ContextMenuType.Highlight
                ) {
                  store.dispatch("util/closeMenu");
                }
                return;
              }

              const { from, to } = state.selection;
              const start = view.coordsAtPos(from),
                end = view.coordsAtPos(to);
              if (
                store.getters["util/getMenu"] == null ||
                store.getters["util/getMenu"].type != ContextMenuType.Highlight
              ) {
                store.dispatch(
                  "util/pushMenu",
                  new HighlightContextMenu(
                    { x: start.left, y: start.top - 36, height: 0 },
                    this.editor as any
                  )
                );
              } else {
                store.dispatch("util/updateMenuLocation", {
                  x: start.left,
                  y: start.top - 36,
                  height: 0,
                });
              }
            },
          };
        },
      }),
    ];
  },
});
