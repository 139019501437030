
import { defineComponent } from "vue";
import Profile from "../navbar/Profile.vue";

export default defineComponent({
  name: "PageLoading",
  components: {
    Profile,
  },
});
