import { Extension } from "@tiptap/core";
import Suggestion from "@tiptap/suggestion";
import store from "@/store/index";
import { Editor, Range } from "@tiptap/core";
import { PluginKey } from "prosemirror-state";
import { EditorCommandContextMenu } from "@/store/util/types";

export const SlashPluginKey = new PluginKey("slash");

const SlashCommands = (options: Array<any>): Extension =>
  Extension.create({
    name: "SlashCommands",

    addOptions() {
      return {
        suggestion: {
          char: "/",
          pluginKey: SlashPluginKey,
          command: ({ editor, range, props }: any) => {
            props.command({ editor, range });
          },
          render: () => {
            return {
              onStart: (props: {
                clientRect: any;
                command: any;
                decorationNode: any;
                editor: Editor;
                query: string;
                range: Range;
                text: string;
              }) => {
                //Initiate the menu
                const rect = props.decorationNode.getBoundingClientRect();
                store.dispatch(
                  "util/pushMenu",
                  new EditorCommandContextMenu(
                    { x: rect.left, y: rect.top, height: rect.height },
                    options,
                    props.query,
                    props.command
                  )
                );
              },
              onUpdate: (props: {
                clientRect: any;
                command: any;
                decorationNode: any;
                editor: Editor;
                query: string;
                range: Range;
                text: string;
              }) => {
                //Update the menu
                store.dispatch("util/updateMenuItems", {
                  query: props.query,
                  command: props.command,
                });
              },
              onKeyDown: (event: any) => {
                store.dispatch("util/menuKeyHandler", event.event);
                if (event.event.key == "Enter") return true;
                return false;
              },
              onExit: () => {
                //Clean up the menu
                store.dispatch("util/closeMenu");
              },
            };
          },
        },
      };
    },

    addProseMirrorPlugins() {
      return [
        Suggestion({
          editor: this.editor,
          ...this.options.suggestion,
        }),
      ];
    },
  });

export default SlashCommands;
