import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative overflow-y-auto scrollbar-sm" }
const _hoisted_2 = { class: "relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PagePath = _resolveComponent("PagePath")!
  const _component_PageHero = _resolveComponent("PageHero")!
  const _component_editor_content = _resolveComponent("editor-content")!
  const _component_Cursors = _resolveComponent("Cursors")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PagePath, {
      class: "top-0 w-full",
      style: { position: 'sticky' }
    }),
    _createVNode(_component_PageHero, {
      class: _normalizeClass(["fountain-slide fall-animation", { fountain: _ctx._fountain }])
    }, null, 8, ["class"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Cursors, {
        editor: _ctx.editor,
        ref: "cursors",
        class: "cursors"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_editor_content, {
            editor: _ctx.editor,
            class: _normalizeClass(["editor flex-grow flex flex-col fountain-slide self-center document fall-animation", { fountain: _ctx._fountain }]),
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
          }, null, 8, ["editor", "class"])
        ]),
        _: 1
      }, 8, ["editor"])
    ])
  ]))
}