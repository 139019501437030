import { Extension } from "@tiptap/core";
import { Plugin, PluginKey } from "prosemirror-state";
import { DecorationSet } from "prosemirror-view";
//import { history } from "prosemirror-history";
import store from "@/store/index";

export interface Cursor {
  type: "+" | "=" | "-";
  uid: string;
  name?: string;
  color?: string;
  pos: {
    anchor: number;
    head: number;
  } | null;
}

export const CollaborationPluginKey = new PluginKey("cursors");

function uuidv4() {
  return (([1e7] as any) + -1e3 + -4e3 + -8e3 + -1e11).replace(
    /[018]/g,
    (c: any) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
  );
}

export default (cursorsRef: any) =>
  Extension.create({
    name: "cursors",
    addStorage() {
      return {
        version: 0,
        uid: (crypto as any).randomUUID
          ? (crypto as any).randomUUID()
          : uuidv4(),
      };
    },
    beforeUnmount() {
      this.storage.websocket.send(
        JSON.stringify({
          type: "remove-user",
          data: {
            uid: this.storage.uid,
          },
        })
      );
    },
    addProseMirrorPlugins() {
      if (!this.editor.extensionStorage.collaboration)
        throw "Cursors need collaboration";

      /* Init User on open */
      this.editor.extensionStorage.collaboration.websocket.addEventListener(
        "open",
        () => {
          (
            this.editor.extensionStorage.collaboration.websocket as WebSocket
          ).send(
            JSON.stringify({
              type: "user-init",
              data: {
                name: store.getters["user/getName"],
                uid: this.storage.uid,
                color: store.getters["user/getSetting"]("display-color"),
              },
            })
          );
        }
      );
      /* Listen for Messages */
      this.editor.extensionStorage.collaboration.websocket.addEventListener(
        "message",
        (message: any) => {
          // Handle an Error
          if (message.data === "status" || message.data === "outside") {
            console.warn("¯_(ツ)_/¯");
          } else {
            const res = JSON.parse(message.data);
            // If it's a cursor change, handle it
            if (res.uid != this.storage.uid) {
              if (res.type == "new-user") {
                cursorsRef.setCursor({
                  type: "+",
                  uid: res.uid,
                  name: res.name,
                  color: res.color,
                  pos: null,
                });
              } else if (res.type == "move-user") {
                cursorsRef.setCursor({
                  type: "=",
                  uid: res.uid,
                  pos: res.pos,
                });
                const tr = this.editor.view.state.tr;
                tr.setMeta("update-cursor", true);
                this.editor.view.dispatch(tr);
              } else if (res.type == "remove-user") {
                cursorsRef.setCursor({
                  type: "-",
                  uid: res.uid,
                  pos: null,
                });
              } else if (res.type == "doc-init") {
                Object.keys(res.cursors).forEach((uid: string) => {
                  cursorsRef.setCursor({
                    type: "+",
                    uid: uid,
                    name: res.cursors[uid].name,
                    color: res.cursors[uid].color,
                    pos: res.cursors[uid].pos,
                  });
                });
              }
            }
          }
        }
      );
      return [
        new Plugin({
          key: CollaborationPluginKey,
          props: {
            decorations(state) {
              return cursorsRef.getCursorHighlights(state.doc);
            },
          },
          state: {
            init(_, { doc }) {
              return DecorationSet.create(doc, []);
            },
            apply: (tr, value, old, state) => {
              cursorsRef.mapCursors(tr);
              /* check if there are sendable document changes */
              if (
                tr.selectionSet &&
                this.editor.extensionStorage.collaboration.websocket
                  .readyState == 1
              ) {
                this.editor.extensionStorage.collaboration.websocket.send(
                  JSON.stringify({
                    type: "move-user",
                    uid: this.storage.uid,
                    pos: {
                      anchor: tr.selection.anchor,
                      head: tr.selection.head,
                    },
                    timestamp: new Date().getTime(),
                  })
                );
              }
            },
          },
        }),
      ];
    },
  });
