import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = {
  class: "relative",
  ref: "cursor-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Cursor = _resolveComponent("Cursor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.editor != null
        ? Object.keys(_ctx.cursors).filter((cursor) => _ctx.cursors[cursor].pos != null)
        : [], (cursor) => {
      return (_openBlock(), _createBlock(_component_Cursor, {
        style: { 'z-index': '5' },
        key: cursor,
        parentPos: _ctx.pos,
        cursor: _ctx.cursors[cursor],
        anchor: _ctx.cursors[cursor].pos.anchor,
        head: _ctx.cursors[cursor].pos.head,
        view: _ctx.editor.view
      }, null, 8, ["parentPos", "cursor", "anchor", "head", "view"]))
    }), 128)),
    _renderSlot(_ctx.$slots, "default")
  ], 512))
}