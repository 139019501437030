
import { defineComponent } from "vue";
import { Page } from "@/store/pages/types";
import { randomEmoji } from "@/assets/emojis";
import store from "@/store/index.ts";
import { ContextMenu, EmojiSelectionContextMenu } from "@/store/util/types";

export default defineComponent({
  name: "PageHero",
  data() {
    return {
      cover: null as any,
    };
  },
  created: function () {
    if (this._cover) this.loadCover(this._cover);
  },
  watch: {
    _cover: function (to, from) {
      if (to) {
        this.loadCover(to);
      }
    },
  },
  computed: {
    _page: function (): string {
      return store.getters["pages/getPage"](
        this.$route.params.pageId as string
      );
    },
    _name: {
      get() {
        return store.getters["pages/getName"](this.$route.params.pageId);
      },
      set(value: string) {
        store.dispatch("pages/setName", {
          page: this.$route.params.pageId as string,
          name: value,
        });
      },
    },
    _icon: function (): string {
      return store.getters["pages/getIcon"](
        this.$route.params.pageId as string
      );
    },
    _cover: function (): string {
      return store.getters["pages/getCover"](
        this.$route.params.pageId as string
      );
    },
    _leads: function (): number {
      return Object.values(
        store.getters["pages/getLeads"](this.$route.params.pageId as string)
      ).length;
    },
  },
  methods: {
    saveName: function () {
      store.dispatch("pages/save", {
        [this.$route.params.pageId as string]: ["name"],
      });
    },
    addEmoji: function () {
      store.dispatch("pages/setIcon", {
        page: this.$route.params.pageId,
        icon: randomEmoji(),
      });
      store.dispatch("pages/save", {
        [this.$route.params.pageId as string]: ["icon"],
      });
    },
    loadCover: function (id: string) {
      store.getters["util/getFile"](id).then(
        (result: { name: string; type: string; file: string }) => {
          this.cover = `data:${result.type};base64,${result.file}`;
        }
      );
    },
    addCover: function () {
      (this.$refs["coverInput"] as any).click();
    },
    storeFile: function (event: any) {
      if (event.target.files[0].size < 400000) {
        const reader = new FileReader();
        const prefixer = /(\w+:(?<type>\w+\/\w+);\w+,)/;
        reader.onload = (e: any) => {
          const type = e.target.result.match(prefixer).groups.type;
          const data = e.target.result.replace(prefixer, "");
          const body = {
            name: event.target.files[0].name,
            type: type,
            file: data,
          };
          store.dispatch("util/storeFile", body).then((result) => {
            if (this._cover) {
              store.dispatch("pages/deleteCover", this.$route.params.pageId);
            }
            store.dispatch("pages/setCover", {
              page: this.$route.params.pageId,
              cover: result._key,
            });
            this.loadCover(result._key);
          });
        };
        reader.readAsDataURL(event.target.files[0]);
      } else {
        window.alert("file too big");
      }
    },
    openEmojiSelect: function () {
      store.dispatch(
        "util/pushMenu",
        new EmojiSelectionContextMenu(
          ContextMenu.getLocation(event),
          (emoji: string) => {
            store.dispatch("pages/setIcon", {
              page: this.$route.params.pageId,
              icon: emoji,
            });
            store.dispatch("pages/save", {
              [this.$route.params.pageId as string]: "icon",
            });
          }
        )
      );
    },
  },
});
