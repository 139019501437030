
import { defineComponent, h } from "vue";
import store from "@/store/index";
import { Page } from "@/store/pages/types";

export default defineComponent({
  name: "PageMention",
  props: {
    page: {
      type: String,
      required: true,
    },
  },
  computed: {
    _page: function (): Page {
      return store.getters["pages/getPage"](this.page);
    },
  },
  render() {
    if (this._page) {
      return h(
        "div",
        {
          class:
            "flex mention p-mention rounded-mention clickable gap-mention fade-in",
        },
        [
          ...(this._page.icon.length > 0
            ? [h("div", { class: "icon-base" }, this._page.icon)]
            : []),
          h("div", { class: "ellipsis flex-1" }, this._page.name),
        ]
      );
    }
    return h(
      "div",
      {
        class:
          "inline-block loading-animation rounded-mention mention text-none",
        style: { width: 80 + 80 * Math.random() + "px" },
      },
      "_"
    );
  },
});
