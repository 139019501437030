import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageMention = _resolveComponent("PageMention")!
  const _component_node_view_wrapper = _resolveComponent("node-view-wrapper")!

  return (_openBlock(), _createBlock(_component_node_view_wrapper, {
    class: "inline-block relative",
    onClick: _ctx.openPage,
    style: { 'vertical-align': 'middle' }
  }, {
    default: _withCtx(() => [
      _createVNode(_component_PageMention, {
        page: _ctx.node.attrs.page,
        "data-drag-handle": ""
      }, null, 8, ["page"])
    ]),
    _: 1
  }, 8, ["onClick"]))
}