import { Node, mergeAttributes } from "@tiptap/core";
import { Plugin } from "prosemirror-state";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    note: {
      /**
       * Toggle a paragraph
       */
      wrapNote: () => ReturnType;
    };
  }
}

export default Node.create({
  name: "note",

  priority: 1000,
  draggable: true,

  addOptions() {
    return {
      HTMLAttributes: {},
      tag: "section",
    };
  },

  group: "note",

  content: "block+",

  parseHTML() {
    return [{ tag: "section" }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "section",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },

  addCommands() {
    return {
      wrapNote:
        () =>
        ({ commands, chain }) => {
          return chain()
            .wrapIn("note")
            .selectParentNode()
            .selectParentNode()
            .wrapIn("collection")
            .focus()
            .run();
        },
    };
  },

  addProseMirrorPlugins() {
    const editor = this.editor;
    return [
      new Plugin({
        props: {
          handleKeyDown(view, event) {
            if (event.ctrlKey && event.shiftKey && event.code == "KeyG") {
              editor.commands.wrapNote();
              return true;
            }
            return false;
          },
        },
      }),
    ];
  },
});
