import { Extension } from "@tiptap/core";
import { Plugin, PluginKey } from "prosemirror-state";
import { Step } from "prosemirror-transform";
import { DecorationSet } from "prosemirror-view";
//import { history } from "prosemirror-history";
import {
  collab,
  sendableSteps,
  receiveTransaction,
  getVersion,
} from "prosemirror-collab";
import store from "@/store/index";

export const CollaborationPluginKey = new PluginKey("collaboration");

export default (websocketURL: string) =>
  Extension.create({
    name: "collaboration",
    addStorage() {
      return {
        websocket: new WebSocket(websocketURL),
        close: () => {
          console.warn("closing websocket");
        },
      };
    },
    addProseMirrorPlugins() {
      this.editor.options.editable = false;
      /* Listen for changes */
      this.storage.websocket.addEventListener("message", (message: any) => {
        // Handle an Error
        if (message.data === "status" || message.data === "outside") {
          console.warn("¯_(ツ)_/¯");
        } else {
          const res = JSON.parse(message.data);
          if (res.type === "doc-init") {
            let steps, clientIds;
            if (res.data.content.length > 0) {
              [steps, clientIds] = res.data.content[0].map(
                (_: any, colIndex: any) =>
                  res.data.content.map((row: any) => row[colIndex])
              );
            } else {
              steps = [];
              clientIds = [];
            }
            try {
              this.editor.view.dispatch(
                receiveTransaction(
                  this.editor.view.state,
                  steps.map((s: any) => Step.fromJSON(this.editor.schema, s)),
                  clientIds
                )
              );
            } catch (error) {
              console.warn("error initializing document", error);
            }

            this.editor.setOptions({ editable: true });
          } else if (res.steps) {
            try {
              const version = getVersion(this.editor.view.state);
              this.editor.view.dispatch(
                receiveTransaction(
                  this.editor.view.state,
                  res.steps.map((s: any) =>
                    Step.fromJSON(this.editor.schema, s)
                  ),
                  res.clientIDs
                )
              );
            } catch (error) {
              console.warn(
                "error confirming transactions",
                error,
                this.editor,
                this.editor.getHTML()
              );
            }
          } else if (res.type == "state") {
            store.dispatch(res.method.replace(/\//, "/_"), res.payload, {
              root: true,
            });
          }
        }
      });

      return [
        collab(),
        new Plugin({
          key: CollaborationPluginKey,
          state: {
            init(_, { doc }) {
              return DecorationSet.create(doc, []);
            },
            apply: (tr, value, old, state) => {
              if (this.storage.websocket.readyState > 1) {
                console.warn("websocket closed, reconnecting");
                this.storage.websocket = new WebSocket(
                  this.storage.websocket.url
                );
              } else if (this.storage.websocket.readyState == 1) {
                /* check if there are sendable document changes */
                const sendable = sendableSteps(state);
                if (sendable) {
                  this.storage.websocket.send(
                    JSON.stringify({
                      version: sendable.version,
                      steps: sendable.steps.map((s) => s.toJSON()),
                      clientID: sendable.clientID,
                      timestamp: {
                        uid: store.getters["user/getUid"],
                        moment: new Date().getTime(),
                      },
                    })
                  );
                }
              }
            },
          },
        }),
      ];
    },
  });
