export enum SuggestionGroup {
  Priority,
  Typeography,
  Formatting,
  Mention,
  Materials,
  Messages,
  Relationships,
  Pages,
  Property,
}

export interface SuggestionItem {
  key: string;
  title: string;
  icon: Array<string> | string | any;
  group: SuggestionGroup;
  command: any;
}
