
import { defineComponent } from "vue";
import PageGuard from "./PageGuard.vue";
import PageContent from "./PageContent.vue";

export default defineComponent({
  name: "Page",
  components: {
    PageGuard,
    PageContent,
  },
  data: function () {
    return {
      valid: false,
    };
  },
  methods: {
    handleValid: function (startClock: number) {
      this.valid = true;
    },
  },
});
