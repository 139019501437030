
import { defineComponent } from "vue";
import store from "@/store/index";
import PageMention from "@/components/pages/mentions/PageMention.vue";
import { NodeViewWrapper, nodeViewProps } from "@tiptap/vue-3";
import { ContextMenu } from "@/store/util/types";

export default defineComponent({
  name: "PageMentionNode",
  components: {
    NodeViewWrapper,
    PageMention,
  },
  props: nodeViewProps,
  computed: {
    _page: function () {
      return store.getters["pages/getPage"]((this.node as any).attrs.page);
    },
  },
  methods: {
    openPage: function () {
      if (this._page) {
        this.$router.push({
          name: "Page",
          params: {
            workspaceId: this.$route.params.workspaceId,
            pageId: (this.node as any).attrs.page,
          },
        });
      }
    },
  },
});
