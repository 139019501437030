import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "flex items-center justify-center",
  style: {
        width: '22px',
        'min-width': '22px',
        height: '27px',
        left: '0',
      }
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_node_view_content = _resolveComponent("node-view-content")!
  const _component_node_view_wrapper = _resolveComponent("node-view-wrapper")!

  return (_openBlock(), _createBlock(_component_node_view_wrapper, {
    class: "flex flex-row flex-nowrap relative",
    as: "li",
    style: { 'list-style': 'georgian' }
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Icon, {
          icon: _ctx.node.attrs.checked ? ['fas', 'square-check'] : ['fal', 'square'],
          class: _normalizeClass(["icon-sm hover:text-blue cursor-pointer", { 'text-pallet-sky': _ctx.node.attrs.checked }]),
          onClick: _ctx.toggle
        }, null, 8, ["icon", "class", "onClick"])
      ]),
      _createVNode(_component_node_view_content, { class: "inline-block" })
    ]),
    _: 1
  }))
}