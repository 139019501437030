
import { defineComponent } from "vue";

export default defineComponent({
  name: "Profile",
  data() {
    return {
      profile: {} as any,
      image: "",
    };
  },
  created: async function () {
    if (await this.$gapi.isSignedIn()) {
      this.$gapi.getCurrentUser().then((result) => {
        this.profile = result.getBasicProfile();
        this.image = this.profile.getImageUrl();
      });
    }
  },
});
