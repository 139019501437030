
import { defineComponent } from "vue";
import store from "@/store/index";

export default defineComponent({
  name: "FountainToggle",
  computed: {
    _fountain: function () {
      return store.getters["workspace/fountain"];
    },
  },
  methods: {
    toggleFountain: function () {
      if (this._fountain) store.dispatch("workspace/toggleNavbar", false);
      else store.dispatch("workspace/toggleNavbar", true);
      store.dispatch("workspace/toggleFountain", !this._fountain);
    },
  },
});
