
import { defineComponent } from "vue";
import { EditorView } from "prosemirror-view";

export default defineComponent({
  name: "Cursor",
  props: {
    parentPos: {
      type: Object,
      required: true,
    },
    cursor: {
      type: Object,
      required: true,
    },
    anchor: {
      type: Number,
      required: true,
    },
    head: {
      type: Number,
      required: true,
    },
    view: {
      type: EditorView,
      required: true,
    },
  },
  watch: {
    anchor: function () {
      if (this.$refs["cursor"]) {
        return (this.$refs["cursor"] as any).parentNode.getBoundingClientRect();
      } else {
        return { left: 0, top: 0, width: 0, height: 0, x: 0, y: 0 };
      }
    },
  },
  computed: {
    width: function (): number {
      return Math.abs(this.anchorPos.left - this.headPos.left);
    },
    height: function (): number {
      return this.anchorPos.top < this.headPos.top
        ? Math.abs(this.headPos.bottom - this.anchorPos.top)
        : Math.abs(this.headPos.top - this.anchorPos.bottom);
    },
    anchorPos: function (): any {
      try {
        const pos = this.view.coordsAtPos(this.anchor);
        if (this.parentPos.left + 50 == pos.left) return this.anchorPos;
        return pos;
      } catch (error) {
        return this.anchorPos;
      }
    },
    headPos: function (): any {
      try {
        const pos = this.view.coordsAtPos(this.head);
        return pos;
      } catch (error) {
        return this.headPos;
      }
    },
  },
});
