import { Node } from "@tiptap/core";

export interface ParagraphOptions {
  tag: "div" | "p";
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    paragraph: {
      /**
       * Toggle a paragraph
       */
      setParagraph: () => ReturnType;
    };
  }
}

export default Node.create<ParagraphOptions>({
  name: "paragraph",

  priority: 1000,
  draggable: true,

  addOptions() {
    return {
      HTMLAttributes: {},
      tag: "p",
    };
  },

  group: "block",

  content: "inline*",

  parseHTML() {
    return [{ tag: this.options.tag }];
  },

  renderHTML() {
    return [this.options.tag, 0];
  },

  addCommands() {
    return {
      setParagraph:
        () =>
        ({ commands }) => {
          return commands.setNode(this.name);
        },
    };
  },
});
